<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card title="Form Mesajı">
        <contact :data-item="dataItem" />
      </b-card>
    </b-col>
    <b-col>
      <div v-if="dataItem.id_com_user">
        <alert-box
          v-if="dataItem.interview_number"
          alert-text="Görüntülemek istediğiniz web talebi kapatılmış."
          alert-variant="danger"
          :route-url="'/interviews/view/' + dataItem.interview_number"
        />
        <alert-box
          v-else-if="userData.id !== dataItem.id_com_user"
          alert-text="Web talebi size atanmamış. İşlem yapmaya yetkiniz bulunmamaktadır."
          alert-variant="warning"
        />
        <validation-observer
          v-else
          ref="simpleRules"
        >
          <b-card title="Görüşme Notu">
            <interview-content />
          </b-card>
          <save-button
            :is-disabled="false"
            :save-action="saveData"
          />
        </validation-observer>
      </div>
      <div v-else>
        <validation-observer
          ref="simpleRules"
        >
          <b-card title="Görüşme Notu">
            <interview-content />
          </b-card>
          <save-button
            :is-disabled="false"
            :save-action="saveData"
          />
        </validation-observer>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import Contact from '@/views/Webforms/components/Contact.vue'
import AlertBox from '@/views/Webforms/components/AlertBox.vue'
import InterviewContent from '@/views/Webforms/components/elements/Contact/InterviewContent.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'ViewForm',
  components: {
    BRow,
    BCol,
    BCard,
    Contact,
    AlertBox,
    InterviewContent,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['webformContact/dataItem']
    },
    saveStatus() {
      return this.$store.getters['webformContact/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config, val.id)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config, val.id)
      }
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    sweetAlert(config, interviewNumber) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(response => {
        if (response.isConfirmed) {
          this.$router.push(`/interviews/view/${interviewNumber}`)
        }
      })
    },
    getData() {
      this.$store.dispatch('webformContact/getDataItem', this.$route.params.id)
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('webformContact/saveData', this.dataItem)
        }
      })
    },
  },
}
</script>
